<template>
  <div>
    <div
      v-for="(fieldRow, index) of fields"
      :key="index"
    >
      <div
        v-if="shouldRenderRow(fieldRow)"
        class="md:flex md:w-full field-row"
      >
        <DynamicLayoutField
          v-for="field of fieldRow"
          :key="field.id"
          :question-id="field.id"
          :questions="questions"
          :document-value="documentValue"
          :entity-type="entityType"
          :value="value"
          class="flex-1 mb-4 mx-2 field"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { toRefs, provide } from '@nuxtjs/composition-api'
import DynamicLayoutField from '@/components/DynamicLayoutField'
import { anyFieldHasValue } from '@/utils/generalUtils'
import useFeatureFlags from '@/composables/useFeatureFlags'
import { HIDE_EMPTY_CARDS } from '@/constants/featureFlags'
import { COURSE_SYLLABUS_CARD_ID } from '@/constants/specialCards'

export default {
  components: { DynamicLayoutField },
  setup(props) {
    const { questions, documentValue, nodeId } = toRefs(props)
    const { hideEmptyCards } = useFeatureFlags({
      hideEmptyCards: HIDE_EMPTY_CARDS,
    })
    const doesAnyFieldHasValue = anyFieldHasValue(
      questions.value,
      documentValue.value
    )

    // For Course Syllabus Card that should hide empty files no matter what settings are
    if (nodeId.value === COURSE_SYLLABUS_CARD_ID) {
      provide('forceHideFieldsWhenValueEmpty', true)
    }

    function shouldRenderRow(fields) {
      return hideEmptyCards ? doesAnyFieldHasValue(fields) : true
    }

    return {
      shouldRenderRow,
    }
  },
  props: {
    fields: {
      type: Array,
      required: true,
    },
    questions: {
      type: Array,
      required: true,
    },
    documentValue: {
      type: Object,
      required: true,
    },
    entityType: {
      type: String,
      default: '',
    },
    nodeId: {
      type: String,
      default: '',
    },
    // eslint-disable-next-line
    value: {
      required: true,
    },
  },
}
</script>

<style scoped>
.field {
  width: initial !important;
}
</style>
